'use client'

import { CSSProperties, Fragment, useEffect, useRef } from 'react'
import { Transition } from '@headlessui/react'
import { shallowEqual, useInterpret, useSelector } from '@xstate/react'
import { twJoin } from 'tailwind-merge'
import { assign, createMachine } from 'xstate'
import { z } from 'zod'

import { Logo } from '../Logo'
import { AnimatedHeadingStrings } from './StackedHeading'

interface AnimatedStackedHeadingProps {
  classNames?: [string?, string?]
  lines: AnimatedHeadingStrings
  small?: boolean
  sameHeight?: boolean
  delay?: number
  duration?: number
}

const loopMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QBkD2qAOA6AkhANmAMQDaADALqKgaqwCWALvagHbUgAeiAzABxYArAHZBfAIzjBIsuOHyANCACeiAGyysZAEzi+AFjIBONX21HhPAL5WlaTFgDqqAE4BreqyhEAavQYARvT4TMoABADGABYAhl6Q5FRIILQMzGwc3AgAtJJqWDzC+uKmwuLaZMJq2kqqCHyWWBp64jz8PIIV2jZ26NjO7p5QWH6BhEScsIwxjGBYMQBmsy4AFAASAKIAggAiGwBKAPo7AKr7WwAqOADyAHIAlET2-a4eXiP+9AGEiRypTCx2Mksp0mtpOnwyGZhLI+HxaohJPoBGROoIdEYeGpTOCeiBnk5XkNfJ8giFGOFonEYBASOIkjQ6ACMsDEeJ9Fg+O19BpRNiyPphAiEEZkVgSuIyDwjF0yNi1DZbCBWKgIHAOM8-kz0kDQFlsrptAUiiUGuVKtVhfosQUeAKeIK9OZLHiCXhCFq0oDMogDTxOWoSmoYXbDPpBGphZ1-Vz7Y6zBZrEqCQM3lBPczdVxfSUOYViqVzVUaip1HCmrJhBY+EYzA0jK6+oTBu9Rl8Pcl-jqfQh-doOuIZfK+Gpa8J4aWEJKOlo2lUTKJpEneg5U0MsGt6BA1Vmu97WQhDUJJXoazzUZ0S3Vp4JZ4VR8HpIIkzYgA */
    id: 'Loop',
    tsTypes: {} as import('./Animated.typegen').Typegen0,
    schema: {
      context: {} as { counter: number; strings: [string, string][] },
      events: {} as { type: 'Set strings'; strings: [string, string][] } | { type: 'Visibility changed' },
    },
    context: {
      counter: 0,
      strings: [],
    },
    initial: 'Idle',
    states: {
      Idle: {
        always: {
          target: 'Working',
          cond: 'Headers strings are valid',
        },
      },

      Working: {
        states: {
          Visible: {
            after: {
              HEADER_DURATION: {
                target: 'Visible',
                internal: false,
                actions: 'Increment counter',
              },
            },
          },
          Hidden: {},
        },

        initial: 'Visible',

        invoke: {
          src: 'Watch window visibility',
          id: 'watchWindowVisibility',
        },

        description: `Use the Window VisibilityState API to detech if the window is in focus.`,

        on: {
          'Visibility changed': [
            {
              target: '.Visible',
              cond: 'Window is visible',
            },
            '.Hidden',
          ],
        },
      },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    delays: {
      HEADER_DURATION: 8000,
    },
    actions: {
      'Increment counter': assign({
        counter: ({ counter, strings }) => (strings.length === 0 ? 0 : (counter + 1) % strings.length),
      }),
    },
    guards: {
      'Headers strings are valid': ({ strings }) =>
        z
          .array(z.tuple([z.string(), z.string()]))
          .min(2)
          .safeParse(strings).success,
      'Window is visible': () => document.visibilityState === 'visible',
    },
    services: {
      'Watch window visibility': () => (send) => {
        const handleVisibilityChange = () => {
          send({ type: 'Visibility changed' })
        }

        window.addEventListener('visibilitychange', handleVisibilityChange)

        handleVisibilityChange()

        return () => window.removeEventListener('visibilitychange', handleVisibilityChange)
      },
    },
  },
)

export function AnimatedStackedHeading({
  classNames: [firstLineClass, subsequentLineClass] = [],
  lines,
  small,
  sameHeight,
  delay = 8000,
  duration,
}: AnimatedStackedHeadingProps) {
  const ref = useRef<HTMLSpanElement>(null)
  const parentRef = useRef<HTMLHeadingElement>()
  const actor = useInterpret(loopMachine, { context: { strings: lines }, delays: { HEADER_DURATION: delay } })
  const { label, counter, strings } = useSelector(
    actor,
    ({ context: { counter, strings } }) => {
      const [firstLine = '', subsequentLine = ''] = strings[counter]
      const label = `${firstLine} ${subsequentLine}`
      return { label, counter, strings }
    },
    shallowEqual,
  )

  const uniqueFirstLines = Array.from(new Set(lines.map(([firstLine]) => firstLine)))
  const uniqueSubsequentLines = Array.from(new Set(lines.map(([, subsequentLine]) => subsequentLine)))

  useEffect(() => {
    const parentElement = ref.current?.closest('h2')
    if (parentElement && !parentRef.current) {
      parentRef.current = parentElement
    }

    parentRef.current?.setAttribute('aria-label', label)
  }, [label])

  const commonTransitionAttributes = {
    style: {
      '--transition-duration': `${duration || delay / 2}ms`,
      '--transition-delay': `${delay / 8}ms`,
    } as CSSProperties,
    enterFrom: 'opacity-0 blur-lg',
    enter: 'transition-[filter,opacity] duration-[--transition-duration] ease-in-out delay-[--transition-delay]',
    enterTo: 'opacity-100 blur-none',
    leaveFrom: 'opacity-100 blur-none',
    leaveTo: 'opacity-0 blur-lg',
    leave: 'transition-[filter,opacity] duration-[--transition-duration] ease-in-out',
    as: 'span',
  } as const

  return (
    <Fragment>
      {uniqueFirstLines.map((firstLine) => (
        <Transition
          key={firstLine}
          show={firstLine === strings[counter][0]}
          className={firstLineClass}
          {...commonTransitionAttributes}
        >
          <span ref={ref}>{firstLine}</span>
        </Transition>
      ))}
      {uniqueSubsequentLines.map((subsequentLine) => (
        <Transition
          key={subsequentLine}
          show={subsequentLine === strings[counter][1]}
          className={subsequentLineClass}
          {...commonTransitionAttributes}
        >
          {subsequentLine === 'DUET' ? (
            <Logo
              className={twJoin('h-12 w-52', !small && 'sm:h-16 sm:w-72', !sameHeight && !small && 'md:h-20 md:w-96')}
            />
          ) : (
            subsequentLine
          )}
        </Transition>
      ))}
    </Fragment>
  )
}
